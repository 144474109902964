import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { Route, RouterModule } from '@angular/router';
import { FormContainerModule } from '@clean-code/shared/components/ui-form-container';
import { UiMatTableModule } from '@clean-code/shared/components/ui-mat-table';
import { PageContainerModule } from '@clean-code/shared/components/ui-page-container';
import { UtilDateModule } from '@clean-code/shared/util/util-date';
import { TranslocoModule } from '@jsverse/transloco';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LimitReportPowerContractAssignmentDialogModule } from '../limit-report-power-contract-assignment-dialog/limit-report-power-contract-assignment-dialog.module';
import { LimitReportPowerListPreviewComponent } from './limit-report-list-preview.component';

const routes: Route[] = [
  {
    path: '',
    component: LimitReportPowerListPreviewComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),

    MatFormFieldModule,
    MatDividerModule,
    MatRadioModule,
    MatCheckboxModule,

    TranslocoModule,
    FormContainerModule,

    PageContainerModule,
    UiMatTableModule,
    NgxPermissionsModule,
    LimitReportPowerContractAssignmentDialogModule,
    UtilDateModule
  ],
  declarations: [LimitReportPowerListPreviewComponent],
  exports: [LimitReportPowerListPreviewComponent],
})
export class LimitReportPowerListPreviewModule {}
