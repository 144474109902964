<h1 mat-dialog-title fxLayout="column">
  <div fxLayout="row">
    {{ 'limit-reports.fields.ASSIGN_LIMIT_REPORT_DIALOG_TITLE' | transloco }}
  </div>
</h1>

<div mat-dialog-content>
  <cc-form-container>
    <form [formGroup]="formGroup" form-content>
      <div class="form-row w-full">
        <div class="form-group w-full">
          <ng-select
            [items]="items$ | async"
            bindLabel="name"
            bindValue="id"
            [multiple]="false"
            appendTo="body"
            [clearable]="false"
            formControlName="offerContractId"
            [typeahead]="input$"
            (change)="changeSelectedContract($event)"
            (focus)="onFocus()"
            placeholder="{{
              'limit-reports.fields.ASSIGN_LIMIT_REPORT_DIALOG_CONTRACT'
                | transloco
            }}"
            required
            [searchable]="true"
          >
            <ng-template ng-label-tmp let-item="item">
              {{ item.name }} - {{ item.amount }} MWh -
              {{ timeSeriesIdentifier }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              {{ item.name }} - {{ item.amount }} MWh -
              {{ timeSeriesIdentifier }}
            </ng-template>
          </ng-select>
        </div>
      </div>
    </form>
  </cc-form-container>
</div>

<div mat-dialog-actions>
  <button
    mat-button
    (click)="dialogRef.close()"
    class="mat-focus-indicator mat-button mat-button-base"
  >
    {{ 'common.CANCEL' | transloco }}
  </button>
  <button
    class="mat-focus-indicator mat-button mat-button-base"
    [ngClass]="formGroup.valid ? 'mat-primary' : 'mat-button-disabled'"
    mat-button
    color="primary"
    [mat-dialog-close]="contractId"
    [disabled]="!formGroup.valid"
  >
    {{ 'limit-reports.button.ASSIGN_LIMIT_REPORT_TO_CONTRACT' | transloco }}
  </button>
</div>
